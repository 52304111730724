import { EnablePhoneNumberAuthFeatureFlag } from "../util/featureFlagUtils";

export type Content = {
  SUBMIT_BUTTON_TEXT: string;
  SYSTEM_ERROR_ALERT_TITLE: string;
  SYSTEM_ERROR_ALERT_MESSAGE: string;
  ENTER_MEMBER_NUMBER_TEXTBOX_LABEL: string;
  ENTER_CONTACT_DETAILS_HEADER_TITLE: string;
  ENTER_CODE_HEADER_TITLE: string;
  ENTER_CODE_SUBMIT_BUTTON_LOADING_TEXT: string;
  ENTER_MEMBER_HELP_TEXT: (brand: string) => string;
  ENTER_CONTACT_DETAILS_HEADER_SUBTEXT: string;
  ENTER_CONTACT_DETAILS_TEXTBOX_LABEL: string;
  ENTER_CONTACT_DETAILS_SUBMIT_BUTTON_LOADING_TEXT: string;
  ENTER_CONTACT_DETAILS_VALIDATION_MESSAGE: string;
  REMEMBER_CONTACT_DETAILS_CHECKBOX_LABEL: string;
  REMEMBER_CONTACT_DETAILS_CHECKBOX_TEXT: string;
  HEADER_SUBTEXT_PART_ONE: string;
  HEADER_SUBTEXT_PART_TWO: string;
  ENTER_CODE_TEXTBOX_LABEL: string;
  ENTER_CODE_VALIDATION_MESSAGE: string;
  ENTER_CODE_WRONG_CODE_MESSAGE: string;
  ENTER_CODE_TOO_MANY_ATTEMPTS_ALERT_TITLE: string;
  ENTER_CODE_TOO_MANY_ATTEMPTS_ALERT_MESSAGE: string;
  ENTER_MEMBER_NUMBER_VALIDATION_MESSAGE: string;
};

export const passwordlessFlowConstants = ({
  language,
  featureFlags,
  brand,
  isSingleSignOn,
  brandName,
}: {
  language?: string;
  featureFlags?: any;
  brand?: string;
  isSingleSignOn?: boolean;
  brandName?: string;
}): Content => {
  const { enablePhoneNumberAuth } = featureFlags;
  return {
    ...baseContent(language),
    ...smsSpecificContent(language, enablePhoneNumberAuth),
    ...brandSpecificContent(language, brand, brandName),
    ...addSingleSignOnContent(language, isSingleSignOn),
  };
};

function baseContent(language?: string): Content {
  switch (language) {
    case "english":
      return {
        SUBMIT_BUTTON_TEXT: "Next",
        SYSTEM_ERROR_ALERT_TITLE: "This service is currently down",
        SYSTEM_ERROR_ALERT_MESSAGE:
          "Some of our services are currently down. We hope to be back up and running soon. Please try again later or",

        //Enter email page
        ENTER_CONTACT_DETAILS_HEADER_TITLE: "Welcome",
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT: "Please enter the email you use for your policy.",
        ENTER_CONTACT_DETAILS_TEXTBOX_LABEL: "Email address",
        ENTER_CONTACT_DETAILS_SUBMIT_BUTTON_LOADING_TEXT: "Sending email",

        ENTER_CONTACT_DETAILS_VALIDATION_MESSAGE: "Please enter a valid email address to continue.",

        REMEMBER_CONTACT_DETAILS_CHECKBOX_LABEL: "Remember my login details",
        REMEMBER_CONTACT_DETAILS_CHECKBOX_TEXT:
          "For security purposes, we recommend leaving this box unchecked on public or shared devices",

        //Enter verification code page
        ENTER_CODE_HEADER_TITLE: "Verify account",
        HEADER_SUBTEXT_PART_ONE: "We've sent a verification code to ",
        HEADER_SUBTEXT_PART_TWO: ". Enter the code to log in to your account.",
        ENTER_CODE_TEXTBOX_LABEL: "Verification code",
        ENTER_CODE_SUBMIT_BUTTON_LOADING_TEXT: "Verifying account",

        ENTER_CODE_VALIDATION_MESSAGE:
          "Something doesn't look quite right! Please check it and try again.",
        ENTER_CODE_WRONG_CODE_MESSAGE:
          "You have entered the wrong code. Please enter the code we sent you.",
        ENTER_CODE_TOO_MANY_ATTEMPTS_ALERT_TITLE: "Incorrect login details",
        ENTER_CODE_TOO_MANY_ATTEMPTS_ALERT_MESSAGE: "You seem to be having problems logging in.",

        //Enter member number
        ENTER_MEMBER_NUMBER_TEXTBOX_LABEL: "Member number",
        ENTER_MEMBER_HELP_TEXT: (brand: string) =>
          `This is the 8-digit number on your ${brand} card.`,
        ENTER_MEMBER_NUMBER_VALIDATION_MESSAGE: `Please enter your 8-digit member number.`,
      };
    default:
      return {} as Content;
  }
}

function smsSpecificContent(
  language?: string,
  enablePhoneNumberAuth?: EnablePhoneNumberAuthFeatureFlag,
) {
  if (!enablePhoneNumberAuth?.isOn()) {
    return {};
  }

  switch (language) {
    case "english":
      return {
        //Enter email page
        ENTER_CONTACT_DETAILS_TEXTBOX_LABEL: "Mobile number or email address",
        ENTER_CONTACT_DETAILS_VALIDATION_MESSAGE:
          "Something doesn't look quite right! Please check it and try again.",
        ENTER_CONTACT_DETAILS_SUBMIT_BUTTON_LOADING_TEXT: "Sending code",
      };
    default:
      return {};
  }
}

function englishSpecificContent(brand?: string, brandName?: string) {
  switch (brand) {
    case "gu":
    case "cignagu":
    case "nib-health-services":
      return {
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT: "Please enter your email address to log in.",
      };
    case "nib":
      return {
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT: "To get started, enter your email",
        ENTER_CONTACT_DETAILS_TEXTBOX_LABEL: "Email address",
      };
    case "nibnz":
      return {
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT:
          "Please enter the email address you use for your policy.",
      };
    case "iap":
      return {
        ENTER_CONTACT_DETAILS_HEADER_TITLE: "nib Gateway",
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT: "Please enter your email address.",
      };
    case "ing":
      return {
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT:
          "Please enter the mobile number or email address you use for your ING Health Insurance policy.",
      };
    case "nibau":
    case "cota":
    case "ns":
    case "qan":
      return {
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT: `Please enter the email address you use to manage ${brandName} Travel Insurance policies.`,
      };
    case "aami":
    case "real":
    case "australianSeniors":
    default:
      return {
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT:
          "Please enter the mobile number or email address you use for your policy.",
      };
  }
}

function brandSpecificContent(language?: string, brand?: string, brandName?: string) {
  switch (language) {
    case "english":
      return englishSpecificContent(brand, brandName);
    default:
      return {};
  }
}

function addSingleSignOnContent(language?: string, isSingleSignOn?: boolean) {
  if (!isSingleSignOn) {
    return {};
  }

  switch (language) {
    case "english":
      return {
        ENTER_CONTACT_DETAILS_HEADER_TITLE: "Welcome back",
        ENTER_CONTACT_DETAILS_HEADER_SUBTEXT: "From time to time we need you to verify your login",
      };
    default:
      return {};
  }
}
